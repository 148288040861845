import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { NotFoundError, UnauthorizedError } from '@medifind/utils';

const handleError = (error) => {
  if (error?.message === 'Request Aborted') return;
  if (!error?.code || (error.code >= 500 && error.code <= 599)) throw error;
  else if (error.code === 403) throw new UnauthorizedError();
  else if (error.code === 404) throw new NotFoundError();
  else throw error;
};

export const useResultRouteQueryResponse = ({ queryKey = [], queryFn, enabled = true, onError, options = {}, select }) => {
  const { data, refetch, isPending, error } = useQuery({
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
    queryKey,
    queryFn: async (...args) => {
      try {
        const res = await queryFn(...args);
        return res;
      } catch (error) {
        if (error?.message !== 'Request Aborted' && !error?.code) {
          console.error(error);
        }
        handleError(error);
      }
    },
    select,
    enabled,
  });

  useEffect(() => {
    if (error && onError) onError(error);
  }, [error]);

  return {
    data,
    error,
    notFound: error instanceof NotFoundError,
    unauthorized: error instanceof UnauthorizedError,
    results: data?.results,
    featuredResults: data?.featuredResults,
    totalResults: data?.totalResults,
    scoresCount: data?.scoresCount,
    refetch,
    isPending,
  };
};
