export class UnauthorizedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnauthorizedError';
    this.code = 403;
    Error.captureStackTrace(this, UnauthorizedError);
  }
}

export class NotFoundError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NotFoundError';
    this.code = 404;
    Error.captureStackTrace(this, NotFoundError);
  }
}
