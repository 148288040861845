import { useEffect } from 'react';
import { getWidgetConfig } from '@medifind/interface';
import { useRouter } from '@medifind/router';
import { initTagManager } from '@medifind/zustand';

const HostedWidgetWrapper = ({ apiKey, children }) => {
  const router = useRouter();
  useEffect(() => {
    if (apiKey)
      getWidgetConfig({ apiKey })
        .then((data) => {
          initTagManager({
            originalLocation:
              document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search,
            hostedWidgetName: data?.name,
          });
        })
        .catch((e) => {
          if (e.code == 403 || e.code == 404) {
            router.history.replace(['/']);
          } else {
            throw e;
          }
        });
  }, [apiKey]);

  return children;
};

export { HostedWidgetWrapper as default, HostedWidgetWrapper };
