import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getAddressDetailsFromPlaceId, getCountryByCode, getProvinceByCode, locationAutocomplete } from '@medifind/interface';
import { useRouter } from '@medifind/router';
import { getFidelity } from '@medifind/utils';
import { setAdditionalRouteParams, setLocationFromQuery, useLocationStore, useRouteStore } from '@medifind/zustand';

const formatStateProvinceCode = (stateProvinceCode) => {
  if (!isNaN(stateProvinceCode)) {
    return stateProvinceCode;
  }
  return stateProvinceCode?.toUpperCase();
};

export const useUrlPathLocation = () => {
  const router = useRouter();
  const {
    routeUUID,
    pathParams: { specialtySlug, countryCode, stateProvinceCode, city, zipPostalCode, projectId, codingSlug },
  } = useRouteStore();
  const [isPendingRedux, setIsPendingRedux] = useState(false);
  const [waitForReduxId, setWaitForReduxId] = useState(null);
  const { id: locationId } = useLocationStore();
  const handleError = (error) => {
    if (error?.code === 404) {
      return error;
    }
    return '';
  };
  const cleanStateProvinceCode = formatStateProvinceCode(stateProvinceCode);

  const {
    data: country,
    isPending: isPendingCountry,
    isError: countryError,
  } = useQuery({
    queryKey: ['getCountryByCode', countryCode],
    queryFn: async () => {
      try {
        return getCountryByCode({ countryCode: countryCode?.toUpperCase() });
      } catch (e) {
        return handleError(e);
      }
    },
    enabled: !!countryCode,
    select: (countryData) => countryData?.preferredName,
    staleTime: Infinity,
    retry: false,
  });

  const {
    data: province,
    isPending: isPendingProvince,
    isError: provinceError,
  } = useQuery({
    queryKey: ['getProvinceByCode', countryCode, cleanStateProvinceCode],
    queryFn: () => {
      try {
        return getProvinceByCode({ countryCode: countryCode?.toUpperCase(), stateProvinceCode: cleanStateProvinceCode, size: 1 });
      } catch (e) {
        return handleError(e);
      }
    },
    enabled: !!countryCode && !!cleanStateProvinceCode,
    select: (data) => {
      const res = data?.[0]?.stateProvinceAsciiName;
      if (!res) {
        throw new Error(404);
      }
      return res;
    },
    staleTime: Infinity,
    retry: false,
  });

  const { data: urlLocation, isPending: isPendingLocation } = useQuery({
    queryKey: ['setUrlLocation', city, country, countryCode, province, stateProvinceCode, zipPostalCode],
    queryFn: async ({ signal }) => {
      const cleanCountryCode = countryCode ? countryCode?.toUpperCase() : null;
      const params = {
        city,
        country,
        countryCode: cleanCountryCode,
        stateProvince: province,
        stateProvinceCode: cleanStateProvinceCode?.toString(),
        zipPostalCode,
      };
      if (country?.code === 404 || province?.code === 404 || (stateProvinceCode && !province)) {
        return null;
      }
      if (country || countryCode) {
        const cleanCountry = country ? country.replace(/^united/i, 'The United') : null;

        if (cleanCountry) params.country = cleanCountry;
        if (city) {
          params.shortLocationString = city;
          params.mediumLocationString = `${params.shortLocationString}${
            cleanStateProvinceCode ? ', ' + cleanStateProvinceCode : ', ' + province
          }`;
          params.locationString = `${params.mediumLocationString}, ${cleanCountryCode ? cleanCountryCode : cleanCountry}`;

          const geocode =
            (await locationAutocomplete({ input: params.locationString }, signal).catch(() => ({ predictions: [] })))?.predictions?.[0] ??
            {};
          const { value } = geocode;
          if (value) {
            const data = await getAddressDetailsFromPlaceId({ placeId: value }).catch(() => ({ result: {} }));
            const place = data?.result;
            if (place.lat && place.lon) {
              params.lat = place.lat;
              params.lon = place.lon;
            }
          } else {
            // we want to null out city / fall back to state if we cant find a geoplaceId
            // this should have the fidelity correctly adjust as well
            // it will be fine that the url doesnt change because it reflects the result for that location
            params.city = null;
          }
        }
        if (!params?.city) {
          if (cleanStateProvinceCode || province) {
            params.shortLocationString = !province ? cleanStateProvinceCode : province;
            params.mediumLocationString = `${params.shortLocationString}, ${cleanCountryCode ? cleanCountryCode : cleanCountry}`;
            params.locationString = params.mediumLocationString;
          } else {
            params.shortLocationString = `${cleanCountry ? cleanCountry : cleanCountryCode}`;
            params.mediumLocationString = params.shortLocationString;
            params.locationString = params.shortLocationString;
          }
        }
        const fidelity = getFidelity(null, zipPostalCode, params.city, params.stateProvinceCode, params.countryCode);
        params.fidelity = fidelity;
      }
      return params;
    },
    enabled: [country, stateProvinceCode ? province : country].filter(Boolean).length === 2,
    staleTime: Infinity,
    retry: false,
  });

  useEffect(() => {
    if (isPendingLocation) setIsPendingRedux(true);
  }, [isPendingLocation]);
  useEffect(() => {
    const cleanCountryCode = countryCode ? countryCode?.toUpperCase() : null;
    const updateCountryCode = countryCode && cleanCountryCode !== countryCode;
    const updateStateCode = stateProvinceCode && cleanStateProvinceCode !== stateProvinceCode;
    if (updateCountryCode || updateStateCode) {
      if (specialtySlug) {
        router.history.replace(`/specialty/${[specialtySlug, cleanCountryCode, cleanStateProvinceCode, city].filter(Boolean).join('/')}`);
      }
      if (projectId) {
        router.history.replace(
          `/conditions/${codingSlug}/${projectId}/doctors/location/${[cleanCountryCode, cleanStateProvinceCode, city]
            .filter(Boolean)
            .join('/')}`,
        );
      }
    }
  }, [specialtySlug, countryCode, stateProvinceCode, city, cleanStateProvinceCode, router.history]);

  useEffect(() => {
    if (urlLocation) {
      const id = setLocationFromQuery(urlLocation);
      setWaitForReduxId(id);
      setAdditionalRouteParams({ urlLocation }, routeUUID);
    }
  }, [urlLocation, routeUUID]);

  useEffect(() => {
    if (locationId == waitForReduxId) {
      setIsPendingRedux(false);
      setWaitForReduxId(null);
    }
  }, [waitForReduxId, locationId]);

  const invalidLocation = !isPendingLocation && !urlLocation;
  const invalidProvince = stateProvinceCode && !isPendingProvince && !province;
  const invalidCountry = countryCode && !isPendingCountry && !country;
  return {
    urlLocation: urlLocation,
    isLoading: isPendingLocation || isPendingCountry || (isPendingProvince && !!stateProvinceCode) || isPendingRedux,
    invalidLocation: countryError || provinceError || invalidCountry || invalidProvince || invalidLocation,
  };
};

export const useUrlQueryLocationString = () => {
  const {
    queryParams: { locationString },
  } = useRouter();

  useEffect(() => {
    const load = async () => {
      const geocode = (await locationAutocomplete({ input: locationString }).catch(() => ({ predictions: [] })))?.predictions?.[0] ?? {};
      const { value } = geocode;
      if (value) {
        const data = await getAddressDetailsFromPlaceId({ placeId: value }).catch(() => ({}));
        if (data?.result) {
          const { addressLine1, city, stateProvinceCode, countryCode, country, zipPostalCode, lat, lon, stateProvince } = data?.result;
          const fidelity = getFidelity(addressLine1, zipPostalCode, city, stateProvinceCode, countryCode);
          const urlLocation = {
            streetAddress: addressLine1,
            fidelity,
            lat,
            lon,
            locationString,
            stateProvince,
            stateProvinceCode,
            country,
            countryCode,
            city,
            zipPostalCode,
            locationOrigin: 'queryText',
          };
          setLocationFromQuery(urlLocation);
        }
      }
    };
    if (locationString) {
      load();
    }
  }, [locationString]);
  return null;
};
